import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Accordion, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  useGetCountryCodeList,
  useStoreProducer,
  useContainerListData,
  useNavigatingAway
} from '@hooks';
import { Formik, Form } from 'formik';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import AlertCommon from '@common/components/AlertCommon';
import { validationSchema } from './AddProducerValidation';
import {
  AccountingPersonDetails,
  AttachmentFiles,
  ContractDetails,
  GeneralDetails,
  OwnerDetails,
  PersonInCharge
} from './components';
import { TNButton } from '@common/components/';
import { CollectDetails } from './components/CollectDetails';
import { WorkDetails } from './components/WorkDetails';
import TNBreadCurm from '@common/components/TNBreadCurm';
import moment from 'moment';
import { DialogLeavingPage } from '@common/components/AlertClosePage';

const AddProducerPage = ({ t }) => {
  // From this api we are getting country code list.
  const navigate = useNavigate();
  const [accrdKey, setAccrdKey] = useState(0);
  const [formState, setFormState] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] = useNavigatingAway(formState);
  const [copyData, setCopyData] = useState({
    country_id: '',
    phone: '',
    email: '',
    ktp: '',
    first_name: '',
    last_name: ''
  });
  const [sectionCopy, setSectionCopy] = useState(null);
  /**
   * !This Api is for country code drop down
   */
  const { isLoading: countryIsLoad, data: countryCodeList } = useGetCountryCodeList();
  let countryCodeOptions = [];
  if (countryCodeList !== undefined) {
    countryCodeOptions = countryCodeList.data.map((item, i) => {
      return { value: item.country_id, label: item.phone_code };
    });
  }

  const { isSuccess: containerListIsSuccess, data: containerListData } = useContainerListData();

  const {
    mutate: doStoreProducer,
    isLoading,
    error: addPRList,
    isError: addPRError
  } = useStoreProducer(
    (res) => {
      toast.success(res.message);
      navigate('/producer/list/');
    },
    (error) => {
      window.scrollTo(0, 0);
    }
  );

  const handleCancel = (dirty) => {
    if (dirty && dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message')
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate(`/producer/list/`);
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        }
      });
    } else {
      navigate(`/producer/list/`);
    }
  };

  if (!containerListIsSuccess) {
    return null;
  }

  const containerData = [];
  if (containerListIsSuccess) {
    containerListData.data.map((value, key) => {
      return containerData.push({
        container_quantity_checkbox: false,
        container_quantity: '',
        container_variable_id: value.container_variable_id,
        label: value.label,
        size: value.size
      });
    });
  }

  /**
   * !This block is for initial values
   */
  const initialValues = {
    // Detail Umum
    name: '',
    simpel_id: '',
    simpel_registration_number: '',
    logo: '',
    logo_id: '',
    producer_npwp_number: '',
    country_id: parseInt(process.env.MIX_DEFAULT_PHONE_CODE),
    phone: '',
    address: '',
    address_notes: '',
    city: '',
    state: '',
    postcode: '',
    country: '',
    latitude: '',
    longitude: '',
    producer_type_id: '',
    sub_producer_type_id: '',
    licence_number: '',
    is_licence: false,
    contract_status: '',
    is_buy_container: 0,
    is_collect_container: 0,
    territory_id: '',
    area_id: '',

    // Detail Koleksi Bisnis
    type_collection: '',
    collect_frequence_type: '',
    waste_codes: [],
    collection_address: '',
    collection_city: '',
    collection_state: '',
    collection_postcode: '',
    collection_country: '',
    collection_latitude: '',
    collection_longitude: '',

    // Detail Bisnis Kerja
    days_slot_1: [
      {
        dayscheckbox: false,
        is_close: false,
        days_start: '',
        days_end: ''
      }
    ],
    days_slot_2: [
      {
        dayscheckbox: false,
        is_close: false,
        days_start: '',
        days_end: ''
      }
    ],
    days_slot_3: [
      {
        dayscheckbox: false,
        is_close: false,
        days_start: '',
        days_end: ''
      }
    ],
    days_slot_4: [
      {
        dayscheckbox: false,
        is_close: false,
        days_start: '',
        days_end: ''
      }
    ],
    days_slot_5: [
      {
        dayscheckbox: false,
        is_close: false,
        days_start: '',
        days_end: ''
      }
    ],
    days_slot_6: [
      {
        dayscheckbox: false,
        is_close: false,
        days_start: '',
        days_end: ''
      }
    ],
    days_slot_7: [
      {
        dayscheckbox: false,
        is_close: false,
        days_start: '',
        days_end: ''
      }
    ],

    // Detail Kontrak Produser
    contract_number: '',
    contract_start_date: '',
    contract_end_date: '',
    contract_signature_date: '',
    contract_note: '',
    agent_id: '',

    // Detail Pemilik Produser
    owner_first_name: '',
    owner_last_name: '',
    owner_email: '',
    owner_country_id: parseInt(process.env.MIX_DEFAULT_PHONE_CODE),
    owner_phone: '',
    owner_ktp: '',

    // Detail Penanggung Jawab
    person_incharge_first_name: '',
    person_incharge_last_name: '',
    person_incharge_email: '',
    person_incharge_country_id: parseInt(process.env.MIX_DEFAULT_PHONE_CODE),
    person_incharge_phone: '',

    // Detail Akunting
    accounting_first_name: '',
    accounting_last_name: '',
    accounting_email: '',
    accounting_country_id: parseInt(process.env.MIX_DEFAULT_PHONE_CODE),
    accounting_phone: '',

    // Lampiran
    owner_ktp_attchment: '',
    selfi_with_ktp_attchement: '',
    business_licence_attchement: '',
    npwp_attchement: '',
    photos_of_premises_attachment: [],
    additional_attachement: [],

    container_data: containerData,
    business_type: 'personal'
  };

  const breadcurmArray = [
    {
      label: t('page.producer_list_label'),
      link: '/producer/list',
      active: ''
    },
    {
      label: t('page.add_producer_title'),
      link: '/producer/list',
      active: 'active'
    }
  ];

  return (
    <>
      <DialogLeavingPage
        showDialog={showDialogLeavingPage}
        setShowDialog={formState}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
        t={t}
      />
      <TNBreadCurm breadcurmArray={breadcurmArray} />
      <AlertCommon key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)} is_error={addPRError}>
        {addPRList}
      </AlertCommon>
      <h1>{t('page.add_producer_title')} </h1>
      <Card>
        <Card.Body className="p-0">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              const formData = new FormData();

              for (let key in values) {
                let value = values[key];

                if (key === 'additional_attachement') {
                  for (let keyatt in value) {
                    let valueatt = value[keyatt];
                    formData.append(
                      `additional_attachement[${keyatt}][additional_attachement_name]`,
                      valueatt.additional_attachement_name
                    );
                    formData.append(
                      `additional_attachement[${keyatt}][additional_attachement_file]`,
                      valueatt.additional_attachement_file
                    );
                  }
                } else if (key === 'photos_of_premises_attachment') {
                  for (let keycoll in value) {
                    let valuecolle = value[keycoll];
                    formData.append(`photos_of_premises_attachment[${keycoll}]`, valuecolle);
                  }
                } else if (key === 'days_slot_1') {
                  formData.append(`dayscheckbox_1`, value[0].dayscheckbox);
                  formData.append(`is_close_1`, value[0].is_close);
                  for (let keyt in value) {
                    let valuet = value[keyt];
                    formData.append(`days_start_1[${keyt}]`, valuet.days_start);
                    formData.append(`days_end_1[${keyt}]`, valuet.days_end);
                  }
                } else if (key === 'days_slot_2') {
                  formData.append(`dayscheckbox_2`, value[0].dayscheckbox);
                  formData.append(`is_close_2`, value[0].is_close);
                  for (let keyt in value) {
                    let valuet = value[keyt];
                    formData.append(`days_start_2[${keyt}]`, valuet.days_start);
                    formData.append(`days_end_2[${keyt}]`, valuet.days_end);
                  }
                } else if (key === 'days_slot_3') {
                  formData.append(`dayscheckbox_3`, value[0].dayscheckbox);
                  formData.append(`is_close_3`, value[0].is_close);
                  for (let keyt in value) {
                    let valuet = value[keyt];
                    formData.append(`days_start_3[${keyt}]`, valuet.days_start);
                    formData.append(`days_end_3[${keyt}]`, valuet.days_end);
                  }
                } else if (key === 'days_slot_4') {
                  formData.append(`dayscheckbox_4`, value[0].dayscheckbox);
                  formData.append(`is_close_4`, value[0].is_close);
                  for (let keyt in value) {
                    let valuet = value[keyt];
                    formData.append(`days_start_4[${keyt}]`, valuet.days_start);
                    formData.append(`days_end_4[${keyt}]`, valuet.days_end);
                  }
                } else if (key === 'days_slot_5') {
                  formData.append(`dayscheckbox_5`, value[0].dayscheckbox);
                  formData.append(`is_close_5`, value[0].is_close);
                  for (let keyt in value) {
                    let valuet = value[keyt];
                    formData.append(`days_start_5[${keyt}]`, valuet.days_start);
                    formData.append(`days_end_5[${keyt}]`, valuet.days_end);
                  }
                } else if (key === 'days_slot_6') {
                  formData.append(`dayscheckbox_6`, value[0].dayscheckbox);
                  formData.append(`is_close_6`, value[0].is_close);
                  for (let keyt in value) {
                    let valuet = value[keyt];
                    formData.append(`days_start_6[${keyt}]`, valuet.days_start);
                    formData.append(`days_end_6[${keyt}]`, valuet.days_end);
                  }
                } else if (key === 'days_slot_7') {
                  formData.append(`dayscheckbox_7`, value[0].dayscheckbox);
                  formData.append(`is_close_7`, value[0].is_close);
                  for (let keyt in value) {
                    let valuet = value[keyt];
                    formData.append(`days_start_7[${keyt}]`, valuet.days_start);
                    formData.append(`days_end_7[${keyt}]`, valuet.days_end);
                  }
                } else if (key === 'container_data') {
                  for (let keyt in value) {
                    let valuet = value[keyt];
                    formData.append(
                      `container_data[${keyt}][container_quantity]`,
                      valuet.container_quantity
                    );
                    formData.append(
                      `container_data[${keyt}][container_quantity_checkbox]`,
                      valuet.container_quantity_checkbox
                    );
                    formData.append(
                      `container_data[${keyt}][container_variable_id]`,
                      valuet.container_variable_id
                    );
                  }
                } else if (key === 'collect_frequence_type') {
                  if (value === 'Day-1') {
                    formData.append('collect_frequence_type', '1');
                    formData.append('collection_frequence', '1');
                  } else if (value === 'Day-2') {
                    formData.append('collect_frequence_type', '1');
                    formData.append('collection_frequence', '2');
                  } else if (value === 'Week-1') {
                    formData.append('collect_frequence_type', '2');
                    formData.append('collection_frequence', '1');
                  } else if (value === 'Week-2') {
                    formData.append('collect_frequence_type', '2');
                    formData.append('collection_frequence', '2');
                  } else if (value === 'Month') {
                    formData.append('collect_frequence_type', '3');
                    formData.append('collection_frequence', '1');
                  }
                } else if (key === 'waste_codes') {
                  value.forEach((v, i) => {
                    formData.append(`waste_codes[${i}]`, v?.value);
                  });
                } else {
                  formData.append(key, value);
                }
              }

              if (values.contract_start_date !== '') {
                formData.append(
                  'contract_start_date',
                  moment(values.contract_start_date).format('YYYY-MM-DD')
                );
              }

              if (values.contract_end_date !== '') {
                formData.append(
                  'contract_end_date',
                  moment(values.contract_end_date).format('YYYY-MM-DD')
                );
              }

              setFormState(false);
              doStoreProducer(formData);
            }}>
            {({ values, errors, dirty }) => {
              values.contract_start_date =
                values.contract_start_date !== '' ? new Date(values.contract_start_date) : '';
              values.contract_end_date =
                values.contract_end_date !== '' ? new Date(values.contract_end_date) : '';
              return (
                <Form>
                  <Accordion
                    key={accrdKey}
                    alwaysOpen
                    defaultActiveKey={accrdKey && accrdKey !== 0 ? accrdKey : 1}>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 1}
                      className="my-1">
                      <Accordion.Header className="text-info fw-bold shadow-nond">
                        {t('page.add_producer_general_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <GeneralDetails
                          t={t}
                          copyData={copyData}
                          setCopyData={setCopyData}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}></GeneralDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 2}
                      className="my-1">
                      <Accordion.Header className="text-info fw-bold shadow-nond">
                        {t('page.add_producer_collect_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <CollectDetails
                          t={t}
                          copyData={copyData}
                          setCopyData={setCopyData}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}
                          stocks={{}}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 3}
                      className="my-1">
                      <Accordion.Header className="text-info fw-bold shadow-nond">
                        {t('page.add_producer_working_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <WorkDetails
                          t={t}
                          copyData={copyData}
                          setCopyData={setCopyData}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}></WorkDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 4}
                      className="my-1">
                      <Accordion.Header>
                        {t('page.add_producer_contact_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <ContractDetails
                          t={t}
                          copyData={copyData}
                          setCopyData={setCopyData}></ContractDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 5}
                      className="my-1">
                      <Accordion.Header>
                        {t('page.add_producer_owner_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <OwnerDetails
                          t={t}
                          copyData={copyData}
                          setCopyData={setCopyData}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}
                          sectionCopy={sectionCopy}
                          setSectionCopy={setSectionCopy}></OwnerDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 6}
                      className="my-1">
                      <Accordion.Header>
                        {t('page.add_producer_person_in_charge_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <PersonInCharge
                          t={t}
                          copyData={copyData}
                          setCopyData={setCopyData}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}
                          sectionCopy={sectionCopy}
                          setSectionCopy={setSectionCopy}></PersonInCharge>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 7}
                      className="my-1">
                      <Accordion.Header>
                        {t('page.add_producer_accounting_person_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <AccountingPersonDetails
                          t={t}
                          copyData={copyData}
                          setCopyData={setCopyData}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}
                          sectionCopy={sectionCopy}
                          setSectionCopy={setSectionCopy}></AccountingPersonDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 8}
                      className="border-bottom my-1">
                      <Accordion.Header>
                        {t('page.add_producer_attachment_title')}{' '}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <AttachmentFiles
                          t={t}
                          copyData={copyData}
                          setCopyData={setCopyData}></AttachmentFiles>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="primary-button">
                    <span className="link-center" onClick={() => handleCancel(dirty)}>
                      {t('page.add_producer_cancel_button')}
                    </span>
                    <TNButton
                      loading={isLoading}
                      onClick={() => {
                        setAccrdKey(1);
                      }}
                      isdirtyform={dirty && dirty !== undefined ? 1 : 0}
                      type="submit">
                      {t('page.add_producer_submit_button')}
                    </TNButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Card.Body>
      </Card>
    </>
  );
};
AddProducerPage.propTypes = {
  t: PropTypes.func
};
export { AddProducerPage };
